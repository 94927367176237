import React, { useState } from "react";
import "./Contact.css";
import { Link } from "react-router-dom";

const Contact = () => {
  const [formData, setFormData] = useState({
    nom: '',
    prenom: '',
    email: '',
    message: '',
  });
// explique ici le fonctionnement du formulaire
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validerFormulaire = async (e) => {
    // Empêcher le rechargement de la page lors de la soumission du formulaire
    e.preventDefault();

    // Vérifier si tous les champs sont remplis
    if (!formData.nom || !formData.prenom || !formData.email || !formData.message) {
      alert('Veuillez remplir tous les champs.');
      // Arrête l'exécution de la fonction si un champ est vide
      return;
    }

    try {
      // fetch pour envoyer une requête HTTP POST
      // Envoie les données du formulaire sous form de JSON 
      const response = await fetch('/data/form', { 
        method: 'POST',
        headers: { // En-têtes de la requête HTTP pour indiquer que le contenu est en JSON
          'Content-Type': 'application/json',
        },
        // Convertit les données du formulaire en une chaîne JSON, qui est envoyée dans le corps de la requête.
        body: JSON.stringify(formData),
      });
//  .ok est une propriété booléenne qui indique si le statut de la réponse HTTP est inclus dans la plage de 200 à 299. 
      if (response.ok) {
        // Affiche une alerte si le message a été envoyé avec succès
        alert('Votre message a été envoyé avec succès.');
        // Réinitialise les valeurs des champs du formulaire
        setFormData({
          nom: '',
          prenom: '',
          email: '',
          message: '',
        });
      } else {
        // Affiche une alerte si le message n'a pas été envoyé avec succès
        alert('Erreur lors de l\'envoi du message.');
      }
    } catch (error) {
      // Affiche une alerte si une erreur se produit lors de l'envoi du message
      alert('Erreur lors de l\'envoi du message.');
    }
  };

  return (
    <section className="contact-section">
      <h2>Contactez-moi</h2>
      <div className="formulaire-container">
        {/* <div className="stars"></div> */}
        <div className="clouds"></div>
        <form method="post" action="/" className="form" onSubmit={validerFormulaire}>
          <div className="input-group">
            <label htmlFor="nom">Nom</label>
            <input
              type="text"
              name="nom"
              id="nom"
              placeholder="Votre Nom"
              value={formData.nom}
              onChange={handleChange}
            />
          </div>
          <div className="input-group">
            <label htmlFor="prenom">Prénom</label>
            <input
              type="text"
              name="prenom"
              id="prenom"
              placeholder="Prénom"
              value={formData.prenom}
              onChange={handleChange}
            />
          </div>
          <div className="input-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Entrez votre courriel"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="input-group">
            <label htmlFor="message">Message</label>
            <textarea
              name="message"
              id="message"
              placeholder="Message"
              value={formData.message}
              onChange={handleChange}
            ></textarea>
          </div>
          <button className="envoyer" type="submit">Envoyer</button>
        </form>
        <div className="social-message">
          <div className="line" />
        </div>
        <div className="social-icons">
          <Link
            aria-label="Contactez-moi sur LinkedIn"
            className="icon infinite"
            to="https://www.linkedin.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa-brands fa-linkedin"></i>
          </Link>
          <Link
            aria-label="Contactez-moi sur GitHub"
            className="icon infinite"
            to="https://github.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa-brands fa-github"></i>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Contact;