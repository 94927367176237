import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Projets.css";
import projet_1 from "../img/AlgoScope-Logo.webp";
import projet_2 from "../img/cimw.webp";
import LogoCakePHP from "../img/cakephp.svg";
import projet_3 from "../img/projet-blague.jpg";
import projet_4 from "../img/portfolio1.webp";
import projet_5 from "../img/projet-Image.jpg";
import projet_6 from "../img/miniSubway.png";
import projet_7 from "../img//jeu-XO.png";

const Projets = ({ butProjet, titreSectionProjet, projetDemo, showAll }) => {
  const [afficherPlus, setAfficherPlus] = useState(false);

  useEffect(() => {
    const customCarousel = document.querySelector(".custom-carousel");

    const toggleActive = (event) => {
      const items = customCarousel.querySelectorAll(".item");
      items.forEach((item) => {
        item.classList.remove("projet-actif");
      });
      event.target.classList.toggle("projet-actif");
    };

    if (customCarousel) {
      customCarousel.addEventListener("click", (e) => {
        if (e.target.classList.contains("item")) {
          toggleActive(e);
        }
      });
    }
  }, []);

  const projects = [
    {
      id: 1,
      backgroundImage: projet_1,
      title: "Site Algoscope",
      description:
        "Lors de mon stage, j'ai transformé le site Algoscope de WordPress en une version statique en HTML, CSS et JavaScript. J'ai ensuite créé une nouvelle version du site.",
      link: "https://algoscope.io/",
    },
    {
      id: 2,
      backgroundImage: projet_2,
      title: "Application CIMW",
      description:
        "Réalisation d'une mini-application de démonstration de chartes graphiques pour la commercialisation de solution web de gestion avec le framework CakePHP",
      link: "http://www.showroomcimw.fr",
    },
    {
      id: 3,
      backgroundImage: projet_3,
      title: "Générateur blagues",
      description:
        "J'adore rire et partager de l'humour. Pour ne jamais oublier mes blagues hilarantes, j'ai créé un générateur de blagues personnel.",
      github: "https://github.com/MohamedEleisawy/Blague",
      link: "https://projet-blague.000webhostapp.com/",
    },
    {
      id: 4,
      backgroundImage: projet_4,
      title: "Version 1 Portfolio",
      description:
        "Réalisation de mon premier portfolio dynamique avec PHP et une base de données MySQL. En integrant phpmailer pour la gestion des mails et un formulaire de contact sécurisé avec reCaptcha. Les données du formulaire sont stockées dans une base de données MySQL. La partie présentaion est modifiable via un back-office.",
      link: "https://mohamedeleisawy.com/ancienPortfolio/",
    },
    {
      id: 5,
      backgroundImage: projet_5,
      title: "Gestionnaire images",
      description:
        "Réalisation de mon premier portfolio dynamique avec PHP et une base de données MySQL.",
      github: "https://github.com/MohamedEleisawy/Projet_Gestion_Images",
      link: "https://projet-gestion-images.alwaysdata.net/",
    },
    {
      id: 6,
      backgroundImage: projet_6,
      title: "Mini-Subway",
      description:
        "Mini-Subawy est un jeu que j'ai réalisé en utilisant HTML, CSS et JavaScript. Le jeu consiste à déplacer un bloc pour éviter des obstacles.",
      github: "https://github.com/MohamedEleisawy/Projet_Mini-Subway",
      link: "https://mohamedeleisawy.github.io/Projet_Mini-Subway/",
    },
    {
      id: 7,
      backgroundImage: projet_7,
      title: "Jeu XO",
      description:
        "Jeu XO est un jeu que j'ai réalisé en utilisant HTML, CSS et JavaScript. Le jeu consiste à aligner 3 symboles pour gagner.",
      github: "https://github.com/MohamedEleisawy/Jeu-XO",
      link: "https://mohamedeleisawy.github.io/Jeu-XO/",
    },
  ];

  const projectsToDisplay = showAll ? projects : projects.slice(0, 3);

  return (
    <>
      <section className="projets">
        {titreSectionProjet && (
          <div className="nom-section-projet">
            <p>Mes Derniers Projets</p>
            <p>03</p>
          </div>
        )}
        {projetDemo && (
          <div className="projet-section">
            <div className="custom-carousel owl-theme">
              {projectsToDisplay.map((project, index) => (
                <div
                  key={project.id}
                  className={`item ${index === 0 ? "projet-actif" : ""}`}
                  style={{
                    backgroundImage: `url(${project.backgroundImage})`,
                  }}
                >
                  <div className="item-desc">
                    <h4 className="h3">{project.title}</h4>
                    <p>
                      {project.description}
                      {index === 0 && (
                        <>
                          <button
                            id="lirePlus"
                            onClick={() => setAfficherPlus(true)}
                            style={{ display: afficherPlus ? "none" : "block" }}
                          >
                            Lire plus
                          </button>
                          <div
                            id="plus"
                            style={{ display: afficherPlus ? "block" : "none" }}
                          >
                            à l'aide d'une maquette fournie pour améliorer la visibilité du site. 
                            <button
                              id="lireMoins"
                              onClick={() => setAfficherPlus(false)}
                              style={{
                                display: afficherPlus ? "block" : "none",
                              }}
                            >
                              Afficher moins
                            </button>
                          </div>
                        </>
                      )}
                    </p>
                    <div className="competanacesEtVisualisationProjets">
                      {index === 0 && (
                        <ul
                          className="competencesProgrammation"
                          aria-label="Compétences en programmation"
                        >
                          <li aria-labelledby="logo-html">
                            <i
                              className="fa-brands logoCompetences fa-html5"
                              id="logo-html"
                              aria-hidden="true"
                            ></i>
                            <abbr
                              title="Hypertext Markup Language"
                              className="tooltip"
                            >
                              HTML
                            </abbr>
                          </li>
                          <li aria-labelledby="logo-css">
                            <i
                              className="fa-brands logoCompetences fa-css3-alt"
                              id="logo-css"
                              aria-hidden="true"
                            ></i>
                            <abbr title="Cascading Style Sheets">CSS</abbr>
                          </li>
                          <li aria-labelledby="logo-js">
                            <i
                              className="fa-brands logoCompetences fa-js"
                              id="logo-js"
                              aria-hidden="true"
                            ></i>
                            <abbr title="Javascript">Javascript</abbr>
                          </li>
                        </ul>
                      )}
                      {index === 1 && (
                        <ul
                          className="competencesProgrammation"
                          aria-label="Compétences en programmation"
                        >
                          <li aria-labelledby="logo-html">
                            <i
                              className="fa-brands logoCompetences fa-html5"
                              id="logo-html"
                              aria-hidden="true"
                            ></i>
                            <abbr
                              title="Hypertext Markup Language"
                              className="tooltip"
                            >
                              HTML
                            </abbr>
                          </li>
                          <li aria-labelledby="logo-css">
                            <i
                              className="fa-brands logoCompetences fa-css3-alt"
                              id="logo-css"
                              aria-hidden="true"
                            ></i>
                            <abbr title="Cascading Style Sheets">CSS</abbr>
                          </li>
                          <li aria-labelledby="logo-js">
                            <i
                              className="fa-brands logoCompetences fa-js"
                              id="logo-js"
                              aria-hidden="true"
                            ></i>
                            <abbr title="Javascript">Javascript</abbr>
                          </li>
                          <li aria-labelledby="logo-php">
                            <img
                              className="logoCakeProjet"
                              src={LogoCakePHP}
                              alt="Logo CakePHP"
                            />
                            <abbr title="Hypertext Preprocessor">CAKEPHP</abbr>
                          </li>
                        </ul>
                      )}
                      {index === 2 && (
                        <ul
                          className="competencesProgrammation"
                          aria-label="Compétences en programmation"
                        >
                          <li aria-labelledby="logo-html">
                            <i
                              className="fa-brands logoCompetences fa-html5"
                              id="logo-html"
                              aria-hidden="true"
                            ></i>
                            <abbr
                              title="Hypertext Markup Language"
                              className="tooltip"
                            >
                              HTML
                            </abbr>
                          </li>
                          <li aria-labelledby="logo-css">
                            <i
                              className="fa-brands logoCompetences fa-css3-alt"
                              id="logo-css"
                              aria-hidden="true"
                            ></i>
                            <abbr title="Cascading Style Sheets">CSS</abbr>
                          </li>
                          <li aria-labelledby="logo-js">
                            <i
                              className="fa-brands logoCompetences fa-js"
                              id="logo-js"
                              aria-hidden="true"
                            ></i>
                            <abbr title="Javascript">Javascript</abbr>
                          </li>
                          <li aria-labelledby="logo-php">
                            <i
                              className="fa-brands logoCompetences fa-php"
                              id="logo-php"
                              aria-hidden="true"
                            ></i>
                            <abbr title="Hypertext Preprocessor">PHP</abbr>
                          </li>
                          <li aria-labelledby="logo-sql">
                            <i
                              className="fa-solid logoCompetences fa-database"
                              id="logo-sql"
                              aria-hidden="true"
                            ></i>
                            <abbr title="Structured Query Language">SQL</abbr>
                          </li>
                        </ul>
                      )}
                      {index === 3 && (
                        <ul
                          className="competencesProgrammation"
                          aria-label="Compétences en programmation"
                        >
                          <li aria-labelledby="logo-html">
                            <i
                              className="fa-brands logoCompetences fa-html5"
                              id="logo-html"
                              aria-hidden="true"
                            ></i>
                            <abbr
                              title="Hypertext Markup Language"
                              className="tooltip"
                            >
                              HTML
                            </abbr>
                          </li>
                          <li aria-labelledby="logo-css">
                            <i
                              className="fa-brands logoCompetences fa-css3-alt"
                              id="logo-css"
                              aria-hidden="true"
                            ></i>
                            <abbr title="Cascading Style Sheets">CSS</abbr>
                          </li>
                          <li aria-labelledby="logo-js">
                            <i
                              className="fa-brands logoCompetences fa-js"
                              id="logo-js"
                              aria-hidden="true"
                            ></i>
                            <abbr title="Javascript">Javascript</abbr>
                          </li>
                          <li aria-labelledby="logo-php">
                            <i
                              className="fa-brands logoCompetences fa-php"
                              id="logo-php"
                              aria-hidden="true"
                            ></i>
                            <abbr title="Hypertext Preprocessor">PHP</abbr>
                          </li>
                          <li aria-labelledby="logo-sql">
                            <i
                              className="fa-solid logoCompetences fa-database"
                              id="logo-sql"
                              aria-hidden="true"
                            ></i>
                            <abbr title="Structured Query Language">SQL</abbr>
                          </li>
                        </ul>
                      )}
                      {index === 4 && (
                        <ul
                          className="competencesProgrammation"
                          aria-label="Compétences en programmation"
                        >
                          <li aria-labelledby="logo-html">
                            <i
                              className="fa-brands logoCompetences fa-html5"
                              id="logo-html"
                              aria-hidden="true"
                            ></i>
                            <abbr
                              title="Hypertext Markup Language"
                              className="tooltip"
                            >
                              HTML
                            </abbr>
                          </li>
                          <li aria-labelledby="logo-css">
                            <i
                              className="fa-brands logoCompetences fa-css3-alt"
                              id="logo-css"
                              aria-hidden="true"
                            ></i>
                            <abbr title="Cascading Style Sheets">CSS</abbr>
                          </li>
                          <li aria-labelledby="logo-php">
                            <i
                              className="fa-brands logoCompetences fa-php"
                              id="logo-php"
                              aria-hidden="true"
                            ></i>
                            <abbr title="Hypertext Preprocessor">PHP</abbr>
                          </li>
                          <li aria-labelledby="logo-sql">
                            <i
                              className="fa-solid logoCompetences fa-database"
                              id="logo-sql"
                              aria-hidden="true"
                            ></i>
                            <abbr title="Structured Query Language">SQL</abbr>
                          </li>
                        </ul>
                      )}
                      {index === 5 && (
                        <ul
                          className="competencesProgrammation"
                          aria-label="Compétences en programmation"
                        >
                          <li aria-labelledby="logo-html">
                            <i
                              className="fa-brands logoCompetences fa-html5"
                              id="logo-html"
                              aria-hidden="true"
                            ></i>
                            <abbr
                              title="Hypertext Markup Language"
                              className="tooltip"
                            >
                              HTML
                            </abbr>
                          </li>
                          <li aria-labelledby="logo-css">
                            <i
                              className="fa-brands logoCompetences fa-css3-alt"
                              id="logo-css"
                              aria-hidden="true"
                            ></i>
                            <abbr title="Cascading Style Sheets">CSS</abbr>
                          </li>
                          <li aria-labelledby="logo-js">
                            <i
                              className="fa-brands logoCompetences fa-js"
                              id="logo-js"
                              aria-hidden="true"
                            ></i>
                            <abbr title="Javascript">Javascript</abbr>
                          </li>
                        </ul>
                      )}
                      {index === 6 && (
                        <ul
                          className="competencesProgrammation"
                          aria-label="Compétences en programmation"
                        >
                          <li aria-labelledby="logo-html">
                            <i
                              className="fa-brands logoCompetences fa-html5"
                              id="logo-html"
                              aria-hidden="true"
                            ></i>
                            <abbr
                              title="Hypertext Markup Language"
                              className="tooltip"
                            >
                              HTML
                            </abbr>
                          </li>
                          <li aria-labelledby="logo-css">
                            <i
                              className="fa-brands logoCompetences fa-css3-alt"
                              id="logo-css"
                              aria-hidden="true"
                            ></i>
                            <abbr title="Cascading Style Sheets">CSS</abbr>
                          </li>
                          <li aria-labelledby="logo-js">
                            <i
                              className="fa-brands logoCompetences fa-js"
                              id="logo-js"
                              aria-hidden="true"
                            ></i>
                            <abbr title="Javascript">Javascript</abbr>
                          </li>
                        </ul>
                      )}
                      <ul className="voirProjet">
                        {project.github && (
                          <li>
                            <Link
                              to={project.github}
                              target="blank"
                              aria-label="Voir le projet sur Github"
                              className=""
                            >
                              <i className="fa-brands fa-github"></i>
                            </Link>
                          </li>
                        )}
                        <li>
                          <Link
                            to={project.link}
                            target="blank"
                            aria-label="Voir le projet"
                            className=""
                          >
                            <i
                              className="fa-solid fa-eye"
                              style={{ color: "#00ae27" }}
                            ></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </section>
      {butProjet && (
        <section className="but-projet">
          <h2>
            En tant que <span>développeur web</span> j'aide les entreprises
            partout en France à augmenter leur trafic grâce à un{" "}
            <span>site web</span> référencé et de haute qualité.
          </h2>
          <svg
            className="signature2"
            viewBox="0 0 600 500"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M 50 400 Q 353 128 250 400 Q 216 499 550 400 Q 800 283 750 400"
              fill="none"
              stroke="#13E9C1"
              strokeWidth="23"
            />
          </svg>
          <hr className="fin-section-projets" />
        </section>
      )}
    </>
  );
};

export default Projets;
