import React from "react";
import Nav from "../components/nav";
import Projets from "../components/projets";
import Footer from "../components/footer";

const Realisation = () => {
  return (
    <>
      <Nav />
      <div className="mt"></div>
      <Projets
        butProjet={false}
        titreSectionProjet={false}
        projetDemo={true}
        showAll={true}
      />

      <Footer />
    </>
  );
};

export default Realisation;
