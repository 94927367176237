// src/index.js
import React from 'react';

import Home from './pages/home';
import Presentation from './pages/presentation';
import Realisation from './pages/realisation';
import Contact from './pages/contact';
import App from './App';
import './index.css';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <h1>Erreur 404 ;</h1>,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/presentation",
        element: <Presentation />,
      },
      {
        path: "/realisation",
        element: <Realisation />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
