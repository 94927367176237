import { useState } from "react";
import "./QuiSuisJe.css";
import stage from "../img/Internship.png";
import diplome from "../img/Student.png";
import siteWeb from "../img/website.svg";
import ecole from "../img/school.svg";
import { Link } from "react-router-dom";

const QuiSuisJe = ({
  lienEnSavoirPlus,
  debutPresentaion,
  presentationComplete,
}) => {

  // const [contenuPresentation1, setContenuPresentation1] = useState(false);
  const [contenuPresentation2, setContenuPresentation2] = useState(false);
  const [contenuPresentation3, setContenuPresentation3] = useState(false);
  const [contenuPresentation4, setContenuPresentation4] = useState(false);
  const [contenuPresentation5, setContenuPresentation5] = useState(false);
  const [contenuPresentation6, setContenuPresentation6] = useState(false);

  // // Fonctions pour basculer l'état de chaque section de contenu
  // const toggleContent1 = () => {
  //   setContenuPresentation1(!contenuPresentation1);
  // };

  const toggleContent2 = () => {
    setContenuPresentation2(!contenuPresentation2);
  };

  const toggleContent3 = () => {
    setContenuPresentation3(!contenuPresentation3);
  };

  const toggleContent4 = () => {
    setContenuPresentation4(!contenuPresentation4);
  };

  const toggleContent5 = () => {
    setContenuPresentation5(!contenuPresentation5);
  };

  const toggleContent6 = () => {
    setContenuPresentation6(!contenuPresentation6);
  };

  return (
    <>
      <section className="qui-Suis-Je">
        <div className="debutPresentation">
          {debutPresentaion && (
            <h2 className="titre">
              Je suis un développeur web de 19 ans, actuellement{" "}
              <span className="highlight">étudiant</span> à Digital Campus Paris. Ma créativité me
              pousse à repousser mes limites. J'aime apprendre de nouvelles
              choses et je suis passionné par le développement web. Je suis à la{" "}
              <span className="highlight">recherche d'une alternance</span> en développement web pour
              mettre en pratique mes compétences et en{" "}
              <span className="highlight">acquérir de nouvelles</span>.
            </h2>
          )}
        </div>
        {presentationComplete && (
          <div className="presentation-complete">
            <h2 className="titre">
              Je suis un développeur web de 19 ans, actuellement
              <span className="highlight"> étudiant</span> à Digital Campus
              Paris. Ma créativité me pousse à repousser mes limites. J'aime{" "}
              <span className="highlight">apprendre de nouvelles choses</span>{" "}
              et je suis passionné par le développement web.
            </h2>
            <h2 className="titre-presentation">Mon Parcours</h2>
            <div className="bloc-histoire">
              <div className="timeline-container">
                <div className="chronologie chronologie-droite rond1">
                  <div className="marker" />
                  <div className="chronologie-contenu">
                    <div className="bloc-date">
                      <h3>2019</h3>
                      <div className="contenu-chronologie">
                        <p>
                          Stage Wordpress : AACCE
                          <img
                            src={stage}
                            alt="Stage Wordpress chez AACCE"
                            width={100}
                          />
                        </p>
                      </div>
                      <div className="contenu-chronologie">
                        <p>
                          Diplôme national du brevet
                          <img
                            src={diplome}
                            alt="Diplôme national du brevet"
                            width={100}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="chronologie chronologie-gauche rond2">
                  <div className="marker" />
                  <div className="chronologie-contenu">
                    <div className="bloc-date">
                      <h3>2020</h3>
                      <div className="contenu-chronologie">
                        <p>
                          1er site web en HTML et CSS
                          <img
                            src={siteWeb}
                            alt="1er site web en HTML et CSS"
                            width={100}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="chronologie chronologie-droite rond1">
                  <div className="marker" />
                  <div className="chronologie-contenu">
                    <div className="bloc-date">
                      <h3>2023</h3>
                      <div className="contenu-chronologie">
                        <p>
                          Diplôme du baccalauréat général : Mathématiques et
                          Numérique Sciences Informatiques
                          <img
                            src={diplome}
                            alt="Diplôme du baccalauréat général"
                            width={100}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="chronologie chronologie-gauche rond2">
                  <div className="marker" />
                  <div className="chronologie-contenu">
                    <div className="bloc-date">
                      <h3>2024</h3>
                      <div className="contenu-chronologie">
                        <p>
                          Bachelor Développement web : Digital Campus Paris
                          <img
                            src={ecole}
                            alt="Bachelor Développement web"
                            width={100}
                          />
                        </p>
                      </div>
                      <div className="contenu-chronologie">
                        <p>
                          Stage Développement web : chez Algoscope
                          <img
                            src={stage}
                            alt="Stage chez Algoscope"
                            width={100}
                          />
                        </p>
                      </div>
                      <div className="contenu-chronologie">
                        <p>
                          Stage Développement web et étude de migration : chez
                          C.I.M.W
                          <img
                            src={stage}
                            alt="Stage chez C.I.M.W"
                            width={100}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p>
              En rejoignant Digital Campus Paris, j'ai eu l'opportunité d'
              <span className="highlight">approfondir mes compétences</span> en
              HTML, CSS et JavaScript, ainsi que de me familiariser avec des
              frameworks modernes tels que Symfony et Vue.js. J'ai également
              mené à bien plusieurs projets variés, allant de sites web
              statiques à des applications web dynamiques intégrant des bases de
              données.
            </p>
            <h2 className="titre-presentation" onClick={toggleContent2}>
              Créativité et Autonomie{" "}
              <i className={`fa fa-chevron-down ${contenuPresentation2 ? "rotate" : ""}`}></i>
            </h2>
            <div
              className={`contenuPresentation ${
                contenuPresentation2 ? "open" : ""
              }`}
            >
              <p>
                Ma créativité me permet de{" "}
                <span className="highlight">
                  trouver des solutions innovantes aux problèmes complexes
                </span>
                , et mon autonomie me donne la capacité de gérer mes projets de
                manière efficace. J'aime{" "}
                <span className="highlight">relever de nouveaux défis</span> et
                explorer des approches pour améliorer mes compétences et les
                projets sur lesquels je travaille.
              </p>
            </div>
            <h2 className="titre-presentation" onClick={toggleContent3}>
              Communication et Travail d'Équipe{" "}
              <i className={`fa fa-chevron-down ${contenuPresentation3 ? "rotate" : ""}`}></i>
            </h2>
            <div
              className={`contenuPresentation ${
                contenuPresentation3 ? "open" : ""
              }`}
            >
              <p>
                J'ai développé de{" "}
                <span className="highlight">
                  solides compétences en communication
                </span>{" "}
                grâce à trois expériences de stage, où j'ai appris à collaborer
                efficacement avec diverses équipes. Ces stages m'ont permis de{" "}
                <span className="highlight">renforcer mon esprit d'équipe</span>{" "}
                et ma capacité à travailler en harmonie avec des collègues.
              </p>
            </div>
            <h2 className="titre-presentation" onClick={toggleContent4}>
              Activités Sportives{" "}
              <i className={`fa fa-chevron-down ${contenuPresentation4 ? "rotate" : ""}`}></i>
            </h2>
            <div className={`contenuPresentation ${contenuPresentation4 ? 'open' : ''}`}>
              <p>
                En dehors du développement web, je suis très actif dans le sport. J'aime particulièrement jouer au{" "}
                <span className="highlight">badminton</span> et au{" "}
                <span className="highlight">football</span>. Ces activités m'aident à{" "}
                <span className="highlight">rester en forme</span> et à{" "}
                <span className="highlight">maintenir un équilibre</span> entre
                travail et vie personnelle.
              </p>
            </div>
            <h2 className="titre-presentation" onClick={toggleContent5}>
              Passions et Loisirs{" "}
              <i className={`fa fa-chevron-down ${contenuPresentation5 ? "rotate" : ""}`}></i>
            </h2>
            <div
              className={`contenuPresentation ${
                contenuPresentation5 ? "open" : ""
              }`}
            >
              <p>
                Au-delà du développement web, j'ai de nombreuses passions. Je
                suis un{" "}
                <span className="highlight">
                  passionné de jeux vidéo et de nouvelles technologies
                </span>
                . Je passe une partie de mon temps libre à explorer de nouveaux
                jeux et à me tenir au courant des dernières tendances
                technologiques.
              </p>
            </div>
            <h2 className="titre-presentation" onClick={toggleContent6}>
              Perspectives d'Avenir{" "}
              <i className={`fa fa-chevron-down ${contenuPresentation6 ? "rotate" : ""}`}></i>
            </h2>
            <div
              className={`contenuPresentation ${
                contenuPresentation6 ? "open" : ""
              }`}
            >
              <p>
                À l'avenir, je souhaite approfondir mes connaissances dans le
                domaine du développement web en explorant{" "}
                <span className="highlight">de nouveaux langages de
                programmation</span> et frameworks. Mon objectif est de{" "}
                <span className="highlight">devenir un expert</span> dans ce
                domaine et de contribuer à des projets innovants qui auront un
                impact positif.
              </p>
            </div>
          </div>
        )}
        {lienEnSavoirPlus && (
          <div className="lien">
            <Link to="/presentation">En savoir plus</Link>  
          </div>
        )}
      </section>
    </>
  );
};

export default QuiSuisJe;