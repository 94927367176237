import React from "react";
import Nav from "../components/nav";
import QuiSuisJe from "../components/quiSuisJe";
import Footer from "../components/footer";

// import { Button, Flex } from 'antd';
// import 'antd';

const Presentation = () => {
  return (

    <>
      <Nav />
    <div className="mt">
    </div>

    <QuiSuisJe presentationComplete={true}  />
      {/* <Flex gap="small" wrap>
    <Button type="primary">Primary Button</Button>
    <Button>Default Button</Button>
    <Button type="dashed">Dashed Button</Button>
    <Button type="text">Text Button</Button>
    <Button type="link">Link Button</Button>
  </Flex> */}
    <Footer />
      {/* <section className="presentation">
        <h2 id="a-propos">À propos</h2>
        <div className="moi">
          <div className="blocCv">
            <div className="cv">
              <a
                href="asset/document/Cv_Mohamed_Eleisawy.pdf"
                target="_blank"
                title="Cliquez pour voir le CV de Mohamed Eleisawy"
              >
                <img
                  src="https://i.postimg.cc/gJ2YY7gD/Capture-d-cran-2024-02-19-053228.png"
                  alt=" CV de Mohamed Eleisawy"
                  loading="lazy"
                  width={300}
                  height={100}
                  title="Le CV de Mohamed Eleisawy"
                />
              </a>
            </div>
            <a
              href="asset/document/Cv_Mohamed_Eleisawy.pdf"
              className="bouton btnCV"
              id="bouton_cv"
              download="asset/document/Cv_Mohamed_Eleisawy.pdf"
            >
              Télécharger mon CV
            </a>
          </div>
        </div>
    </section> */}
    </>
  );
};

export default Presentation;
