import Home from './pages/home';
import Presentation from './pages/presentation';
import Realisation from './pages/realisation';
import Contact from './pages/contact';
// import 'antd';
import { Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/presentation" element={<Presentation />} />
        <Route path="/realisation" element={<Realisation />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </div>
  );
}

export default App;