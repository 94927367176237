import React from "react";
import "./Service.css";
import Mohamed from "../img/momo.png";
// import aaa from "../img/developpement-web.png";
// import aa from "../img/web-development-svg.svg";
import a from "../img/developpement.png";
import Design from "../img/design-svgrepo-com.svg";
import Graphisme from "../img/graphisme.png";
import LogoReact from "../img/raect.png";
import LogoCakePHP from "../img/cakephp.svg";
import canvas from "../img/canvas.png";
import figma from "../img/figma.svg";
import balsamiq from "../img/balsamiq.png";

const Service = () => {
  return (
    <section className="service">
     
      <div className="nom-section-service">
        <p>Services</p>
        <p>04</p>
      </div>
      <div className="container-image-service">
        <div class="fond-blur">
          <div class="fond-css-blurry-gradient"></div>
        </div>
        <div class="fond-blur2">
          <div class="fond-css-blurry-gradient2"></div>
        </div>
        <div class="fond-blur3">
          <div class="fond-css-blurry-gradient3"></div>
        </div>
        <img
          class="mohamed"
          src={Mohamed}
          alt="Mohamed"
          width={300}
          height={400}
        />
        <div className="toutBlocs">
          <div className="fleches1">
            <div className="bulle-developpement-web1">
              <div className="logos">
                <img
                  src={LogoReact}
                  width={35}
                  height={35}
                  alt=""
                  className="logo-react bounceInRoatat infinite"
                />
                <img
                  src={LogoCakePHP}
                  width={35}
                  height={35}
                  alt=""
                  className="logo-cakephp infinite swing"
                />
                <i
                  class="fa-brands logoCompetences fa-symfony logo-symfony taille-logo swing infinite"
                  id="logo-symfony-competance "
                  aria-hidden="true"
                ></i>
                <i
                  class="fa-brands logoCompetences fa-js logo-js taille-logo  tada infinite "
                  aria-hidden="true"
                ></i>
                <i
                  class="fa-brands logoCompetences fa-php logo-php taille-logo swing infinite"
                  id="logo-php-competance"
                  aria-hidden="true"
                ></i>
                <abbr title="Hypertext Preprocessor"></abbr>
                <i
                  class="fa-solid logoCompetences fa-database logo-sql taille-logo swing infinite"
                  aria-hidden="true"
                ></i>
                <i
                  class="fa-brands logoCompetences fa-html5 logo-html taille-logo reverseflip infinite"
                  id="logo-html-competance"
                  aria-hidden="true"
                ></i>
                <abbr title="Hypertext Markup Language" class="tooltip"></abbr>
                <i
                  class="fa-brands logoCompetences fa-css3-alt logo-css taille-logo flip infinite"
                  id="logo-css-competance"
                  aria-hidden="true"
                ></i>
              </div>
              <div className="bulle">
                <img className="developpement" src={a} alt="developpement" />
              </div>
              <p>Développement Web</p>
            </div>
            <div className="fleche fleche1"></div>
          </div>
          {/*  */}

          <div className="fleches2">
            <div className="bulle-developpement-web2">
              <div className="logo-competance">
                <div className="logos">
                  <img
                    src={canvas}
                    width={35}
                    height={35}
                    alt="logo canvas"
                    className="logo-canvas taille-logo "
                  />
                  <img
                    src={figma}
                    width={35}
                    height={35}
                    alt="logo figma"
                    className="logo-figma taille-logo"
                  />
                  <img
                    src={balsamiq}
                    width={35}
                    height={35}
                    alt="logo balsamiq"
                    className="logo-balsamiq taille-logo"
                  />
                  <img
                    src="https://i.postimg.cc/sMjbTHTv/photoshop.webp"
                    className="taille-logo logo-photoshop"
                    alt="Logo Photoshop"
                    width="35"
                    height="35"
                    loading="lazy"
                    title="Logo Photoshop"
                  />
                </div>
              </div>
              <div className="bulle">
                <img className="design" src={Design} alt="design" />
              </div>
              <p>Conception UX / UI</p>
            </div>
            <div className="fleche fleche2"></div>
          </div>

          {/*  */}
          <div className="fleches3">
            <div className="bulle-developpement-web3">
              <div className="logos">
                <img
                  src="https://i.postimg.cc/RNynR2ST/illustrator.webp"
                  className="taille-logo logo-illustrator infinite swing"
                  alt="Logo Illustrator"
                  width="35"
                  height="35"
                  loading="lazy"
                  title="Logo Illustrator"
                />
                <img
                  src="https://i.postimg.cc/rD4RYgz2/indesign.webp"
                  className="taille-logo logo-indesign infinite swing "
                  alt="Logo InDesign"
                  width="35"
                  height="35"
                  loading="lazy"
                  title="Logo InDesign"
                />
              </div>
              <div className="bulle">
                <img className="graphisme" src={Graphisme} alt="graphisme" />
              </div>
              <p>Graphisme</p>
            </div>
            <div className="fleche fleche3"></div>
          </div>
          {/*  */}
          {/* <div className="fleche fleche4"></div> */}
          {/* <div className="fleche fleche5"></div> */}
        </div>
      </div>
    </section>
  );
};

export default Service;
