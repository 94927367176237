import {Link} from "react-router-dom";
import Nav from "../components/nav";
import Header from "../components/header";
import QuiSuisJe from "../components/quiSuisJe";
import Projets from "../components/projets";
import Service from "../components/service";
import Contact from "../components/contact";
import Footer from "../components/footer";
const Home = () => {
  return (
    <>
      <Nav />
      <Header />
      {/* methode 1 d'insretion  */}
      <QuiSuisJe debutPresentaion={true}  lienEnSavoirPlus={<p className="savoir-plus">
            <Link to="presentation">En savoir plus</Link>
            <i className="fa-solid fa-arrow-right fleche-droite"></i>
          </p>} />
          {/* methode 2 d'insertion */}
      <Projets 
  butProjet={true} 
  titreSectionProjet={true} 
  projetDemo={true} 
  showAll={false} 
/>

      <Service />
      <Contact />
      <Footer />
    </>
  );
};

export default Home;
