import React from "react";
import Nav from "../components/nav.jsx";
import CONTACATEZ_MOI from "../components/contact.jsx";
import Footer from "../components/footer.jsx";

const Contact = () => {
  return (
    <>
      <Nav />
      <div className="espace-contact"></div>
      <CONTACATEZ_MOI />
      <Footer />

    </>
  );
};

export default Contact;
